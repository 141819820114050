.custom-calendar {
    width: 100%;
    /*max-width: 305px;*/
    font-size: 14px;
}

.react-datepicker__header {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
}

.react-datepicker__day-name {
    width: 38px;
    text-align: center;
    font-size: 12px;
    color: #344054;
}

.react-datepicker__day {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 50%;
}

.react-datepicker__month {
    padding: 20px;
}

.react-datepicker__day--selected {
    background-color: #4a90e2;
    color: white;
    font-weight: bold;
}

.react-datepicker__month--selected .react-datepicker__month-text {
    @apply bg-brand-300;
}

.react-datepicker__day--outside-month {
    color: #ccc;
}

.react-datepicker__navigation {
    top: 10px;
}

.react-datepicker__navigation-icon {
    width: 14px;
    height: 14px;
}

.react-datepicker__week {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__month {
    margin: 0 auto;
}
