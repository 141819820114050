.LoginPage {
  margin: auto;
  display: flex;
  flex-direction: row;
}

.LoginPage__login-section {
  width: 640px;
}

@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

.LoginPage__screen-greeting {
  position: absolute;
  top: 150px;
  right: 50px;
  width: 87%;
  /*max-width: 696px;*/
  min-width: 150px;
}

.LoginPage__screen-table {
  position: absolute;
  top: 300px;
  right: 0;
  width: 70%;
  max-width: 696px;
  min-width: 200px;
}

.LoginPage__phone-greeting {
  position: absolute;
  top: 110px;
  right: 150px;
  width: 30%;
  max-width: 250px;
  min-width: 100px;
}

/* Ukrycie sekcji na małych ekranach */
@media only screen and (max-width: 768px) {
  .LoginPage__prom {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .LoginPage__logo {
    display: none;
  }

  .LoginPage__container {
    padding-top: 25px;
    justify-content: start;
    overflow-y: auto;
  }
}
